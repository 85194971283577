




































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

import { AccountInfo } from "@azure/msal-browser";
import { BusyList, BusyObject } from '@/models/Busy';
import { BrandBookmarkContainer, StoredBrandQueriesService, StoredBrandQuery } from '@/api/braendz';

import SectionHeader from '@/components/Headers/SectionHeader.vue';
import BrandBookmarkContainerSlider from '@/components/BrandBookmarks/BrandBookmarkContainerSlider.vue';
import StoredBrandQuerySlider from '@/components/StoredBrandQueries/StoredBrandQuerySlider.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
import BrandBookmarkContainerCard from '@/components/BrandBookmarks/BrandBookmarkContainerCard.vue';
import StoredBrandQueryCard from '@/components/StoredBrandQueries/StoredBrandQueryCard.vue';

@Component({
    components: {
        BrandBookmarkContainerSlider,
        StoredBrandQuerySlider,
        ConfirmationPopup,
        BrandBookmarkContainerCard,
        StoredBrandQueryCard,
        SectionHeader
    },
    metaInfo() {
        return {
            title: this.$i18n.t("myBraendz.title").toString()
        };
    }
})
export default class MyBraendz extends Vue {
   
    // Properties
    public navigationDrawer = true;

    public storedBrandQueries = new BusyList<BusyObject<StoredBrandQuery>>();

    // Getter:
    public get userAccount(): AccountInfo | null {
        return this.$store.state.userAccount;
    }

    public get brandBookmarkContainers(): BusyObject<BrandBookmarkContainer>[] {
        return (this.$store.state.brandBookmarkContainers as BusyList<BusyObject<BrandBookmarkContainer>>).list;
    }

    // Component lifecycle methods:
    public mounted() {
        this.refreshStoredBrandQueries();
    }

    // Methods:
    public getIdOrIndex(item: BrandBookmarkContainer | StoredBrandQuery | undefined | null, index: number): string {
        return item?.id ? `Id_${item?.id}` : `Index_${index}`;
    }

    public deleteContainer(container: BrandBookmarkContainer | null | undefined): void {
        if(container?.id) {
            this.$store.dispatch('removeBrandBookmarkContainer', container.id);
        }
    }

    public async refreshStoredBrandQueries() {
        await this.storedBrandQueries.load(async () => {
            return (await StoredBrandQueriesService.getStoredBrandQueriesCurrentUserDefaultAccount()).map(i => new BusyObject<StoredBrandQuery>(i));
        });
    }

    public onStoredBrandQueryChanged(existing: BusyObject<StoredBrandQuery>, changed: any) {
        const changedItem = this.storedBrandQueries.list.find(i => i === existing);
        if(changedItem) {
            changedItem.object = changed;
        }
    }
}
