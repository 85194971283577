var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":20}},[_c('v-container',{style:({'padding-left': (_vm.indent + 12) + 'px'}),attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"elevation":"0","short":"","color":"transparent"}},[(_vm.storedBrandQuery.object)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.storedBrandQuery.object.name))]):_c('v-toolbar-title',[_vm._v("Loading...")]),_c('v-spacer'),(_vm.storedBrandQuery.object)?_c('ExecuteStoredBrandQueryPopup',{attrs:{"defaultSize":_vm.storedBrandQuery.object.query.size,"executing":_vm.lastBrandQueryResult.isBusy},on:{"execute":_vm.executeStoredBrandQuery},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.storedBrandQuery.object && _vm.storedBrandQuery.object.id)?_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-light fa-play")]),_c('span',[_vm._v("Execute")])],1):_vm._e()]}}],null,false,3836035144),model:{value:(_vm.executeStoredBrandQueryPopupVisible),callback:function ($$v) {_vm.executeStoredBrandQueryPopupVisible=$$v},expression:"executeStoredBrandQueryPopupVisible"}}):_vm._e(),(_vm.storedBrandQuery.object)?_c('ConfigureStoredBrandQueryPopup',{attrs:{"storedBrandQuery":_vm.storedBrandQuery.object},on:{"storedBrandQueryChanged":_vm.fireStoredBrandQueryChanged},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.storedBrandQuery.object && _vm.storedBrandQuery.object.id)?_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-light fa-gears")]),_c('span',[_vm._v("Configure")])],1):_vm._e()]}}],null,false,2131876793),model:{value:(_vm.configureStoredBrandQueryPopupVisible),callback:function ($$v) {_vm.configureStoredBrandQueryPopupVisible=$$v},expression:"configureStoredBrandQueryPopupVisible"}}):_vm._e(),_c('ConfirmationPopup',{attrs:{"yes":"","cancel":"","question":"","title":"Löschen","text":"Möchten Sie diese gespeicherte Suche unwiderruflich löschen?"},on:{"yes":_vm.deleteStoredBrandQuery},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.storedBrandQuery.object && _vm.storedBrandQuery.object.id)?_c('v-btn',_vm._g(_vm._b({attrs:{"plain":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-light fa-trash-can")]),_c('span',[_vm._v("Delete")])],1):_vm._e()]}}]),model:{value:(_vm.deleteStoredBrandQueryConfirmationVisible),callback:function ($$v) {_vm.deleteStoredBrandQueryConfirmationVisible=$$v},expression:"deleteStoredBrandQueryConfirmationVisible"}}),(_vm.brandQueryHistoryItems.isBusy)?_c('v-progress-linear',{attrs:{"indeterminate":"","absolute":"","bottom":""}}):_vm._e()],1)],1)],1),(_vm.storedBrandQuery.object && _vm.storedBrandQuery.object.description)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"info"}},[_vm._v(_vm._s(_vm.storedBrandQuery.object.description))])],1)],1):_vm._e()],1),_c('div',{staticClass:"d-block"},[(_vm.brandQueryHistoryItems.list.length > 0)?_c('StoredBrandQuerySlider',{attrs:{"brandQueryHistoryItems":_vm.brandQueryHistoryItems,"storedBrandQuery":_vm.storedBrandQuery.object}}):_c('v-row',{staticStyle:{"height":"150px"},attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_vm._v(" No results available ")])],1)],1),(_vm.lastBrandQueryResult.object)?_c('BrandQueryResultPopup',{attrs:{"brandQueryResult":_vm.lastBrandQueryResult.object,"storedBrandQuery":_vm.storedBrandQuery.object},model:{value:(_vm.lastBrandQueryResultPopupVisible),callback:function ($$v) {_vm.lastBrandQueryResultPopupVisible=$$v},expression:"lastBrandQueryResultPopupVisible"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }