













import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BusyList, BusyObject } from '@/models/Busy';
import { Brand, BrandBookmark, BrandBookmarkContainer, BrandsService } from '@/api/braendz';

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import VerticalBrandTile from '@/components/Brands/VerticalBrandTile.vue';


@Component({
    components: {
        VerticalBrandTile,
        VueSlickCarousel
    }
})
export default class BrandBookmarkContainerSlider extends Vue {

    // Properties
    public brands = new BusyList<BusyObject<Brand>>();
    
    @Prop({ required: true })
    public container!: BrandBookmarkContainer;

    // Getter & Setter
    public get brandItemsToShow(): number {
        return Math.ceil(window.innerWidth / 550);
    }
    
    // Watcher:
    @Watch('container')
    public onContainerChanged() {
        this.refreshBrands();
    }

    // Lifecycle Methods:
    public mounted() {
        this.refreshBrands();
    }

    // Methods:
    public refreshBrands(): void {
        this.brands.reset();

        if(this.container?.brandBookmarks) {
            for(const bookmark of this.container.brandBookmarks) {
                const busyBrand = new BusyObject<Brand>();
                this.brands.list.push(busyBrand);
                busyBrand.load(async () => {
                    return BrandsService.getBrand(bookmark.id);
                });
            }
        }
    }
}
