























































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BrandQueriesService, BrandQueryHistoryItem, BrandQueryResult, StoredBrandQuery } from '@/api/braendz';
import { BusyObject } from '@/models/Busy';

import BrandQueryResultPopup from '@/components/BrandQueryResults/BrandQueryResultPopup.vue'

@Component({
    components: {
        BrandQueryResultPopup
    }
})
export default class BrandQueryHistoryItemTile extends Vue {
    // Member
    public showBrandQueryResult = false;
    public brandQueryResult = new BusyObject<BrandQueryResult>();


    // Properties
    @Prop({ required: true })
    public item!: BusyObject<BrandQueryHistoryItem>;

    @Prop({ required: false })
    public storedBrandQuery?: StoredBrandQuery;

    // Getter
    public get executionOrCreationTimeUtc(): string | null | undefined {
        return this.item.object?.executedUtc ?? this.item.object?.createdUtc;
    }

    public get storedResultsPercentage(): number | null {
        if(this.item.object?.totalResultsCount && this.item.object?.storedResultsCount) {
            return this.item.object.storedResultsCount / this.item.object.totalResultsCount * 100;
        }
        return null;
    }

    // Methods
    public async showResult() {
        if(!this.item.object?.id) {
            return;
        }

        if(!this.brandQueryResult.object) {
            await this.brandQueryResult.load(async () => {
                if(this.item.object?.id) {
                    return BrandQueriesService.getBrandQueryHistoryItemResult(this.item.object.id);
                }
                return null;
            });
        }

        if(this.brandQueryResult.object) {
            this.showBrandQueryResult = true;
        }
    }
}
