





































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BusyList, BusyObject } from '@/models/Busy';

import { BrandBookmarkContainer } from '@/api/braendz';

import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
import BrandBookmarkContainerSlider from '@/components/BrandBookmarks/BrandBookmarkContainerSlider.vue';


@Component({
    components: {
        BrandBookmarkContainerSlider,
        ConfirmationPopup,
    }
})
export default class BrandBookmarkContainerCard extends Vue {
    // Member
    public showDeleteContainerConfirmation = false;

    // Properties
    @Prop({ required: true })
    public container!: BusyObject<BrandBookmarkContainer>;

    @Prop({ required: false, default: 0, type: Number })
    public indent!: number;

    // Getter & Setter

    // Lifecycle Methods:

    // Methods:
    public deleteContainer(): void {
        if(this.container.object?.id) {
            this.$store.dispatch('removeBrandBookmarkContainer', this.container.object.id);
        }
    }
}
