













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BusyList, BusyObject } from '@/models/Busy';
import { BrandQueryHistoryItem, StoredBrandQuery } from '@/api/braendz';

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import BrandQueryHistoryItemTile from '@/components/BrandQueryResults/BrandQueryHistoryItemTile.vue';


@Component({
    components: {
        BrandQueryHistoryItemTile,
        VueSlickCarousel
    }
})
export default class StoredBrandQuerySlider extends Vue {

    // Properties
    @Prop({ required: true })
    public brandQueryHistoryItems!: BusyList<BusyObject<BrandQueryHistoryItem>>;

    @Prop({ required: false })
    public storedBrandQuery?: StoredBrandQuery;

    // Getter & Setter
    public get itemsToShow(): number {
        return Math.ceil(window.innerWidth / 550);
    }

    // Lifecycle Methods:

    // Methods:
    public getIdOrIndex(item: BrandQueryHistoryItem | null | undefined, index: number) {
        return item?.id ?? index;
    }
    

}
