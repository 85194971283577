








































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { BrandQueryResult, StoredBrandQuery } from '@/api/braendz';

import VerticalBrandTileGrid from '@/components/Brands/VerticalBrandTileGrid.vue';
import HorizontalBrandTileGrid from '@/components/Brands/HorizontalBrandTileGrid.vue';
import BrandTable from '@/components/Brands/BrandTable.vue';

@Component({
    components: {
        VerticalBrandTileGrid,
        HorizontalBrandTileGrid,
        BrandTable
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class BrandQueryResultPopup extends Vue {
    public activeBrandViewTabIndex = 0;

    // Properties
    @Prop({ required: false, default: false, type: Boolean })
    public model!: boolean;

    @Prop({ required: true })
    public brandQueryResult!: BrandQueryResult;

    @Prop({ required: false })
    public storedBrandQuery?: StoredBrandQuery;

    @Prop({ required: false, default: 'Results' })
    public title!: string;

    // Getter
    public get visible(): boolean {
        return this.model;
    }
    public set visible(value: boolean) {
        this.fireVisibilityChanged(value);
    }

    // Lifecycle Methods:

    // Methods:
    public close(): void {
        this.visible = false;
    }

    public fireVisibilityChanged(value: boolean): void {
        this.$emit('visibilityChanged', value);
    }
}
