
































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class SectionHeader extends Vue {

    // Properties
    @Prop({ required: true })
    public title!: string;

    @Prop({ required: false })
    public icon?: string;

    @Prop({ required: false, type: Boolean, default: false })
    public underlined!: boolean;

    // Getter & Setter

    // Lifecycle Methods:

    // Methods:
}
